import axiosService from "lib/axios";
import { useQuery } from "@tanstack/react-query";

const route = "/jobs";
export async function getJobs(page:number, limit:number, search?:string,field?:string):Promise<{
    jobs:Job[];
    page:number;
    limit:number;
    totalCount:string;
}> {
    const { data } = await axiosService.get(route, {
        params: { page, limit, search, field },
    });
    if (data.count) {
        window.localStorage.setItem("totalCountJobs", data.count);
    }
    return {
        jobs: data.jobs,
        page: data.page,
        limit: data.limit,
        totalCount: data.count || window.localStorage.getItem("totalCountJobs"),
    };
}

async function getJob (id:string):Promise<{
    job:Job;
    jobDetailsCount:string
}> {
    const { data } = await axiosService.get(`${route}/${id}`);
    return data;
}

export function useGetJob(id:string){
    return useQuery(["job", id], ()=>getJob(id));
}

export function deleteJobs (ids: number[] | string[]){
    return axiosService.delete(route, {data: {ids}});
} 

export function deleteSingleJob (id: number | string, secretKey: string){
    return axiosService.delete(`${route}/${id}?secretKey=${secretKey}`);
} 

export interface Job{
    id:number;
    name:string;
    monday:boolean;
    tuesday:boolean;
    wednesday:boolean;
    thursday:boolean;
    friday:boolean;
    saturday:boolean;
    sunday:boolean;
    day_last_mailed:string;
    include_ISP:string;
    exclude_ISP:string;
    mailwizz_campaign:string;
    mailwizz_list:string;
    recurring:boolean;
    daily_lead_cap:number;
    status:string;
    list_ids:number[];
    notes:string;
    dailyLeadCap:number;
}
export async function createJob(job:Partial<Job>){
    const { data } = await axiosService.post(route, job);
    return data;
}

export async function editJob(job:Partial<Job>){
    const { data } = await axiosService.put(`${route}/${job.id}`, job);
    return data;
}