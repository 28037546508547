import axiosService from "lib/axios";
import { useQuery } from "@tanstack/react-query";

const route = "/logs";
export async function getLogs():Promise<{
    logs: any;
}> {
    const { data } = await axiosService.get(route);

    return {
        logs: data.logs !== null && data.logs !== '' && Object.entries(data.logs).length !== 0 ? JSON.parse(data.logs) : data.logs
    };
}

export function deleteLog (){
    return axiosService.delete(route);
}