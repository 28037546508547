import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppBar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from "@mui/material";
import Loading from "components/ui/Loading";
import { useAppContext } from "lib/contextLib";
import { deleteLog, getLogs } from "services/logs";
import theme from "theme";
import { drawerWidth } from "../constants";
import { LogoutUsers } from "services/user";
import Paper from '@mui/material/Paper';

const Logs = () => {
  // const logs: any = getLogs().then((data: any) => {
  //     console.log(data);
  //     setLogsData(data);
  //   });
    // const { logs, getAllLogs } = useLogs();
    // const [isEditing, setIsEditing] = React.useState(false);
    // const { data, isLoading, isError, refetch } = useGetJob();
  const { setSnackbarState } = useAppContext();
  const [ logsData, setLogsData ] = useState<any>({});
  const [open, setOpen ] = useState(false);
  const [isDeleting, setIsDeleting ] = useState(false);
  const [isLoading, setIsLoading ] = useState(true);
  
  const navigate = useNavigate();
  
  const [delSecret, setDelSecretKey] = useState("");
  
  useEffect(() => {
    fetchData();
  }, [])
  
  const fetchData = async() => {
    await getLogs().then((data: any) => {
      setLogsData(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }).catch((e) => {
      setIsLoading(false);
    });
  }
  
  const handleClose = () => {
    setOpen(false);
  }

  const deleteLogs = async() => {
    await deleteLog().then((data) => {
      setSnackbarState({
        open: true,
        message: "Logs deleted successfully",
        color: "success",
      });
      setIsDeleting(false);
      fetchData();
      setOpen(false);
    }).catch((e) => {
      setIsDeleting(false);
    })
  }

  const handleOk = () => {
    setIsDeleting(true);
    deleteLogs();
  }

  return (
    <Grid container direction="column">
      {isLoading ? (
        <Loading />
      ) : (

        <>
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {"Are You Sure?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Once completed, it cannot be reversed.
            </DialogContentText>
            <TextField
              autoFocus
              required
              margin="dense"
              id="secret"
              name="secret"
              label="Secret Key"
              type="text"
              onChange={(e: any) => setDelSecretKey(e.target.value)}
              fullWidth
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {handleClose()}}>Disagree</Button>
            <Button onClick={handleOk} autoFocus disabled={delSecret === ''}>
              {
                isDeleting ? <CircularProgress size={20} /> : "Agree"
              }
            </Button>
          </DialogActions>
        </Dialog>
        
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            border: `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              variant="h3"
              id="tableTitle"
              component="div"
            >
              Logs    
            </Typography>

            <Button
              variant="outlined"
              onClick={() => {
                LogoutUsers();
                navigate("/login");
              }}
              className="btnLogin"
              sx={{ ml: 2, marginLeft: "auto" }}
            >
              Logout
            </Button>
          </Toolbar>
        </AppBar>

        <Grid container direction={"column"}>
          {/* {(isError) && <ErrorAlert />} */}
          {/* {(isLoading) && <Loading />} */}
          {logsData && Object.entries(logsData?.logs).length !== 0 && !isLoading && (
            <>
            <Box sx={{marginBottom: "20px", display: "flex", alignItems: "center"}}>
               <Box>
                 <Typography variant="h4">Logs Data CRON Job</Typography>
               </Box>
               <Box sx={{marginLeft: "auto"}}>
               <Button
                  onClick={() => {setOpen(true)}}
                >
                  Clear Logs
                </Button>
               </Box>
             </Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Message</TableCell>
                    <TableCell align="center">Code</TableCell>
                    <TableCell align="center">Stack</TableCell>
                    <TableCell align="center">Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">{logsData.logs.error.message}</TableCell>
                    <TableCell align="center">{logsData.logs.error.code}</TableCell>
                    <TableCell align="center">{logsData.logs.error.stack}</TableCell>
                    <TableCell align="center">{logsData.logs.error.message}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </>
          )}
          { !isLoading && Object.entries(logsData?.logs).length === 0 && (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", top: "50%" }}>
              <Typography variant="h4">No Logs Found</Typography>
            </Box>
          )}
        </Grid>
        </>
      )}
    </Grid>
  );
};

export default Logs;
